import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axiosConfig';

export const fetchNotes = createAsyncThunk(
  'notes/fetchNotes',
  async ({ page = 1, tag = null, bookmarked = false }, { rejectWithValue }) => {
    try {
      let url = `/api/notes?page=${page}`;
      if (tag) {
        url += `&tag=${encodeURIComponent(tag)}`;
      }
      if (bookmarked) {
        url += '&bookmarked=true';
      }
      console.log('Fetching notes from URL:', url);
      const response = await axios.get(url);
      console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching notes:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while fetching notes');
    }
  }
);

export const addNote = createAsyncThunk(
  'notes/addNote',
  async ({ content, autoTags, removedAutoTags }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/notes', { content, autoTags, removedAutoTags });
      console.log('Add note response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding note:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while adding the note');
    }
  }
);

export const updateNote = createAsyncThunk(
  'notes/updateNote',
  async ({ id, content, tags }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/notes/${id}`, { content, tags });
      console.log('Update note response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating note:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while updating the note');
    }
  }
);

export const deleteNote = createAsyncThunk(
  'notes/deleteNote',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/notes/${id}`);
      console.log('Note deleted:', id);
      return id;
    } catch (error) {
      console.error('Error deleting note:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while deleting the note');
    }
  }
);

export const toggleNoteLock = createAsyncThunk(
  'notes/toggleNoteLock',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-lock`);
      console.log('Toggle note lock response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error toggling note lock:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while toggling the note lock');
    }
  }
);

export const toggleNoteBookmark = createAsyncThunk(
  'notes/toggleNoteBookmark',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-bookmark`);
      console.log('Toggle note bookmark response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error toggling note bookmark:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while toggling the note bookmark');
    }
  }
);

export const toggleNoteCollapse = createAsyncThunk(
  'notes/toggleNoteCollapse',
  async ({ id, isCollapsed }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-collapse`, { isCollapsed });
      console.log('Toggle note collapse response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error toggling note collapse:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while toggling the note collapse');
    }
  }
);

export const addTag = createAsyncThunk(
  'notes/addTag',
  async ({ noteId, tag }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/notes/${noteId}`, { tags: [tag] });
      console.log('Add tag response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding tag:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while adding the tag');
    }
  }
);

export const removeTag = createAsyncThunk(
  'notes/removeTag',
  async ({ noteId, tag }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/notes/${noteId}`, { tags: [tag], removeTag: true });
      console.log('Remove tag response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error removing tag:', error);
      return rejectWithValue(error.response?.data || 'An error occurred while removing the tag');
    }
  }
);

const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    currentPage: 1,
    totalPages: 1,
  },
  reducers: {
    updateNoteOptimistic: (state, action) => {
      const index = state.items.findIndex(note => note._id === action.payload._id);
      if (index !== -1) {
        state.items[index] = action.payload;
      }
    },
    deleteNoteOptimistic: (state, action) => {
      state.items = state.items.filter(note => note._id !== action.payload);
    },
    toggleNoteCollapseOptimistic: (state, action) => {
      const { id, isCollapsed } = action.payload;
      const note = state.items.find(note => note._id === id);
      if (note) {
        note.isCollapsed = isCollapsed;
      }
    },
    addTagOptimistic: (state, action) => {
      const { noteId, tag } = action.payload;
      const note = state.items.find(note => note._id === noteId);
      if (note && !note.tags.includes(tag)) {
        note.tags.push(tag);
      }
    },
    removeTagOptimistic: (state, action) => {
      const { noteId, tag } = action.payload;
      const note = state.items.find(note => note._id === noteId);
      if (note) {
        note.tags = note.tags.filter(t => t !== tag);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.notes;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.error = null;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Unknown error occurred';
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.items.unshift(action.payload);
        state.error = null;
      })
      .addCase(addNote.rejected, (state, action) => {
        state.error = action.payload || 'Failed to add note';
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const index = state.items.findIndex(note => note._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.error = action.payload || 'Failed to update note';
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.items = state.items.filter(note => note._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.error = action.payload || 'Failed to delete note';
      })
      .addCase(toggleNoteLock.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.isLocked = action.payload.isLocked;
        }
        state.error = null;
      })
      .addCase(toggleNoteLock.rejected, (state, action) => {
        state.error = action.payload || 'Failed to toggle note lock';
      })
      .addCase(toggleNoteBookmark.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.isBookmarked = action.payload.isBookmarked;
        }
        state.error = null;
      })
      .addCase(toggleNoteBookmark.rejected, (state, action) => {
        state.error = action.payload || 'Failed to toggle note bookmark';
      })
      .addCase(toggleNoteCollapse.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.isCollapsed = action.payload.isCollapsed;
        }
        state.error = null;
      })
      .addCase(toggleNoteCollapse.rejected, (state, action) => {
        state.error = action.payload || 'Failed to toggle note collapse';
      })
      .addCase(addTag.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.tags = action.payload.tags;
        }
        state.error = null;
      })
      .addCase(addTag.rejected, (state, action) => {
        state.error = action.payload || 'Failed to add tag';
      })
      .addCase(removeTag.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.tags = action.payload.tags;
        }
        state.error = null;
      })
      .addCase(removeTag.rejected, (state, action) => {
        state.error = action.payload || 'Failed to remove tag';
      });
  },
});

export const { 
  updateNoteOptimistic, 
  deleteNoteOptimistic,
  toggleNoteCollapseOptimistic,
  addTagOptimistic,
  removeTagOptimistic
} = notesSlice.actions;

export default notesSlice.reducer;